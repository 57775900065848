import React, { useState } from "react";
import {
  Card,
  CardHeader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Grid,
  Divider,
  List,
  Button,
  Checkbox,
} from "@mui/material";
import { CustomTypographyComponent } from "@patron/ui/text";

export interface CustomTransferListProps {
  callingCompTransferListId: string;
  leftList: TransferListDataStructure[];
  isLeftListError?: boolean;
  leftListErrorMessage?: string;
  leftListTitle: string;
  leftListSubtitle?: string;
  rightList: TransferListDataStructure[];
  isRightListError?: boolean;
  rightListErrorMessage?: string;
  rightListTitle: string;
  rightListSubtitle?: string;
  widthLeft?: string | number;
  widthRight?: string | number;
  heightLeft?: string | number;
  heightRight?: string | number;
  containerWidth?: string | number;
  containerHeight?: string | number;

  onSubmitClicked: (rightList: TransferListDataStructure[]) => void;
}

export interface TransferListDataStructure {
  ID: string;
  description: string;
}

const not = (
  a: readonly TransferListDataStructure[],
  b: readonly TransferListDataStructure[]
) => a.filter((value: TransferListDataStructure) => b.indexOf(value) === -1);

const intersection = (
  a: readonly TransferListDataStructure[],
  b: readonly TransferListDataStructure[]
) => a.filter((value: TransferListDataStructure) => b.indexOf(value) !== -1);

const union = (
  a: readonly TransferListDataStructure[],
  b: readonly TransferListDataStructure[]
) => [...a, ...not(b, a)];

export const CustomTransferList = React.forwardRef(
  (
    {
      callingCompTransferListId,
      leftList,
      isLeftListError = false,
      leftListErrorMessage,
      leftListTitle,
      leftListSubtitle,
      rightList,
      isRightListError = false,
      rightListErrorMessage,
      rightListTitle,
      rightListSubtitle,
      widthLeft,
      widthRight,
      heightLeft,
      heightRight,
      containerWidth,
      containerHeight,
      onSubmitClicked,
    }: CustomTransferListProps,
    ref
  ) => {
    const [checked, setChecked] = React.useState<
      readonly TransferListDataStructure[]
    >([]);
    const [leftListInternal, setLeftListInternal] = useState<
      TransferListDataStructure[]
    >(leftList.length > 0 ? leftList : []);
    const [rightListInternal, setRightListInternal] = useState<
      TransferListDataStructure[]
    >(rightList.length > 0 ? rightList : []);

    const leftChecked = intersection(checked, leftListInternal);
    const rightChecked = intersection(checked, rightListInternal);

    const handleToggle = (value: TransferListDataStructure) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    };

    const numberOfChecked = (items: readonly TransferListDataStructure[]) =>
      intersection(checked, items).length;

    const handleToggleAll =
      (items: readonly TransferListDataStructure[]) => () => {
        if (numberOfChecked(items) === items.length) {
          setChecked(not(checked, items));
        } else {
          setChecked(union(checked, items));
        }
      };

    const handleCheckedRight = () => {
      setRightListInternal(rightListInternal.concat(leftChecked));
      setLeftListInternal(not(leftListInternal, leftChecked));
      setChecked(not(checked, leftChecked));
    };

    const handleAllRight = () => {
      setRightListInternal(rightListInternal.concat(leftListInternal));
      setLeftListInternal([]);
    };

    const handleCheckedLeft = () => {
      setLeftListInternal(leftListInternal.concat(rightChecked));
      setRightListInternal(not(rightListInternal, rightChecked));
      setChecked(not(checked, rightChecked));
    };

    React.useImperativeHandle(ref, () => ({
      onSubmit() {
        onSubmitClicked(rightListInternal);
      },
    }));

    const customList = (
      title: React.ReactNode,
      items: readonly TransferListDataStructure[],
      subTitle?: string,
      width?: string | number,
      height?: string | number
    ) => (
      <Card>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                "aria-label": "all items selected",
              }}
              style={{ padding: 2, paddingLeft: 9 }}
              size="small"
            />
          }
          title={title}
          subheader={
            subTitle
              ? subTitle
              : `${numberOfChecked(items)}/${items.length} selected`
          }
        />
        <Divider />
        <List
          sx={{
            minWidth: 250,
            minHeight: 175,
            width: width ? width : 400,
            height: height ? height : 300,
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((value: TransferListDataStructure) => {
            const labelId = `transfer-list-all-item-${value.description}-label`;

            return (
              <ListItem
                key={value.ID}
                role="listitem"
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.includes(value)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                    style={{ padding: 2 }}
                    size="small"
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value.description}`} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
    return (
      <Stack
        width={containerWidth ? containerWidth : 1000}
        id={`CustomTransferList${callingCompTransferListId}`}
      >
        {(leftListInternal || rightListInternal) && (
          <Grid
            container
            spacing={2}
            mt={2}
            height={containerHeight ? containerHeight : 310}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              {customList(
                leftListTitle,
                leftListInternal,
                leftListSubtitle,
                widthLeft,
                heightLeft
              )}
            </Grid>
            {isLeftListError && (
              <Stack>
                <CustomTypographyComponent color={"red"}>
                  {leftListErrorMessage}
                </CustomTypographyComponent>
              </Stack>
            )}

            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5, height: 30 }}
                  variant="contained"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected left"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5, height: 30 }}
                  variant="contained"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected right"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              {customList(
                rightListTitle,
                rightListInternal,
                rightListSubtitle,
                widthRight,
                heightRight
              )}
            </Grid>
            {isRightListError && (
              <Stack>
                <CustomTypographyComponent color={"red"}>
                  {rightListErrorMessage}
                </CustomTypographyComponent>
              </Stack>
            )}
          </Grid>
        )}
      </Stack>
    );
  }
);

CustomTransferList.displayName = "CustomTransferList";
export default CustomTransferList;
