import React, { ReactNode } from "react";
import { SelectProps, Select } from "@mui/material";

export type CustomSelectComponentProps = SelectProps & {
  controldisabled?: boolean;
  useFullWidth?: boolean;
  children: ReactNode;
  size?: string;
  height?: number | string;
  width?: number | string;
};

export const CustomSelectComponent = ({
  size = "small",
  useFullWidth = true,
  controldisabled,
  children,
  ...others
}: CustomSelectComponentProps) => (
  <Select
    id="CustomSelect"
    disabled={controldisabled}
    variant="filled"
    fullWidth={useFullWidth ? true : false}
    sx={{
      height: others.height,
      width: others.width,
    }}
    size={size}
    error={others.error}
    {...others}
  >
    {children}
  </Select>
);

export default CustomSelectComponent;
