import React, { ReactNode } from "react";
import { Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DateTimePickerProps } from "@mui/x-date-pickers/DateTimePicker";
import "dayjs/locale/nl";
import dayjs, { Dayjs } from "dayjs";
import styled from "@emotion/styled";
import { lighten } from "@mui/material";
import { SetDefaultLocale } from "./UpdateLocale";

export type CustomDateTimePickerComponentProps =
  DateTimePickerProps<unknown> & {
    controldisabled?: boolean;
    children?: ReactNode;
    width?: number;
    required?: boolean;
    error?: boolean;
    format?: string;
    value?: Dayjs | string;
  };

const StyledDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
  "&.MuiFilledInput-root .Mui-disabled ": {
    //@ts-ignore
    backgroundColor: lighten(theme.appColors.surface, 0.6),
    //@ts-ignore
    color: lighten(theme.appColors.onSurface, 0.4),
    pointerEvents: "unset",
    "&&:hover": {
      cursor: "default",
    },
  },
}));

export const CustomDateTimePickerComponent = (
  props: CustomDateTimePickerComponentProps
) => {
  const {
    controldisabled,
    width,
    required,
    error,
    format,
    value,
    slotProps,
    ...rest
  } = props;
  SetDefaultLocale();
  return (
    <Stack width={width ? width : "100%"} id="CustomDateTimePickerComponent">
      <LocalizationProvider adapterLocale={"nl"} dateAdapter={AdapterDayjs}>
        <StyledDateTimePicker
          disabled={controldisabled}
          format={format ? format : "DD/MM/YYYY HH:mm"}
          timezone="Europe/Paris"
          value={value ? dayjs(value) : null}
          slotProps={{
            textField: {
              variant: "filled",
              size: "small",
              error: error,
              required: required,
              InputProps: {
                required: required,
                error: error,
                disabled: controldisabled,
              },
            },
            ...slotProps,
          }}
          {...rest}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default CustomDateTimePickerComponent;
