import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers-pro";
import "dayjs/locale/nl";
import dayjs, { Dayjs } from "dayjs";
import styled from "@emotion/styled";
import { SetDefaultLocale } from "./UpdateLocale";

export type CustomDatePickerComponentProps = DatePickerProps<unknown> & {
  controldisabled?: boolean;
  children?: ReactNode;
  width?: number | string;
  required?: boolean;
  error?: any;
  format?: string;
  value?: Dayjs | string;
  textFieldFullWidth?: boolean;
};

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiFilledInput-root .Mui-disabled ": {
    //@ts-ignore
    color: theme.appPalette.neutral[30],
    //@ts-ignore
    WebkitTextFillColor: theme.appPalette.neutral[30],
    pointerEvents: "unset",
    "&&:hover": {
      cursor: "default",
    },
    "& .MuiFormLabel-root-MuiInputLabel-root-Mui-disabled": {
      //@ts-ignore
      color: theme.appPalette.neutral[30],
      //@ts-ignore
      backgroundColor: theme.appColors.surfaceContainer,
      //@ts-ignore
      WebkitTextFillColor: theme.appPalette.neutral[30],
    },
  },
}));

export const CustomDatePickerComponent = (
  props: CustomDatePickerComponentProps
) => {
  const {
    controldisabled,
    width,
    required,
    error,
    format,
    value,
    slotProps,
    ...rest
  } = props;
  SetDefaultLocale();

  return (
    <Box width={width ? width : 165} id="CustomDatePickerComponent">
      <LocalizationProvider adapterLocale={"nl"} dateAdapter={AdapterDayjs}>
        <StyledDatePicker
          disabled={controldisabled}
          format={format ? format : "DD/MM/YYYY"}
          value={value ? dayjs(value) : null}
          timezone="Europe/Paris"
          sx={{
            width: width ? width : 165,
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: "filled",
              size: "small",
              error: error,
              required: required,
              InputProps: {
                required: required,
                error: error,
                disabled: controldisabled,
              },
            },
            ...slotProps,
          }}
          {...rest}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default CustomDatePickerComponent;
