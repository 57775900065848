import React from "react";
import { Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Calendar from "@mui/icons-material/Event";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DateRangePickerProps } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import dayjs, { Dayjs } from "dayjs";
import { SetDefaultLocale } from "./UpdateLocale";

type CustomDateRangePickerComponentProps = DateRangePickerProps<unknown> & {
  controldisabled?: boolean;
  width?: number;
  required?: boolean;
  error?: any;
  format?: string;
  value?: [Dayjs, Dayjs] | [string, string];
};

export const CustomDateRangePickerComponent = (
  props: CustomDateRangePickerComponentProps
) => {
  const { controldisabled, width, required, error, format, value, ...rest } =
    props;

  SetDefaultLocale();
  return (
    <Stack width={width ? width : "100%"} id="CustomDateRangePickerComponent">
      <LocalizationProvider adapterLocale={"nl"} dateAdapter={AdapterDayjs}>
        <DateRangePicker
          disabled={controldisabled}
          format={format ? format : "DD/MM/YYYY"}
          timezone="Europe/Paris"
          slots={{ field: SingleInputDateRangeField }}
          slotProps={{
            textField: {
              InputProps: {
                required: required,
                error: error,
                disabled: controldisabled,
                endAdornment: <Calendar />,
              },
              variant: "filled",
              size: "small",
              required: required,
            },
          }}
          value={
            value && value.length === 2 && value[0] !== undefined
              ? [dayjs(value[0]), dayjs(value[1])]
              : undefined
          }
          {...rest}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default CustomDateRangePickerComponent;
